// @flow
import React from 'react';
import { graphql } from 'gatsby';
import {
  AllImagesLink,
  CanyonRatingCard,
  Carousel,
  GlossaryLink as G,
  Image,
  Layout,
  withFrontMatter,
} from '../../components';
import type { FrontMatter, ImageType } from '../../utils/types';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
  },
};

class Blog_2021_07_05_Adams_Canyon extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CII" title="Adams Canyon" />
        <Image image={data._1} />
        <p>
          This Independence Day weekend we got to rappel a wonderful little
          waterfall. The majority of the 2 mile trail is shaded which makes it a
          pleasant but steep hike.
        </p>
        <Carousel
          images={[data._2, data._3]}
          captions={[
            'Scrambling up to get to the top of the waterfall',
            'Taking a break on the way up',
          ]}
        />
        <p>
          We arrived at the falls, geared up, and headed up a <G>cross joint</G>{' '}
          that leads to the top of the falls. It requires a bit of{' '}
          <G id="4th-class-scramblings">scrambling</G> so the trip leader set up
          a <G>hand line</G> for easier access.
        </p>
        <Carousel
          images={[data._4, data._5, data._6, data._7, data._8, data._9]}
          captions={[
            'Rappel 1',
            'Rappel 1',
            'Rappel 1',
            'Rappel 1',
            'Rappel 1',
            'Rappel 1',
          ]}
        />
        <p>
          The first rappel is short but requires the rappeller to go right into
          the flow. Coupled with the moss it's easy to slip but has low penalty
          points.
        </p>
        <Carousel
          images={[data._10, data._11]}
          captions={[
            'hand line between first and last rappel',
            'hand line between first and last rappel (and a giant selfie stick)',
          ]}
        />
        <p>
          Between the first and last rappel is a little slippery so a hand line
          can be set up <G id="rdc">right down canyon</G> using a bolt at the
          start and end.
        </p>
        <Carousel
          images={[data._12, data._13, data._14, data._15]}
          captions={[
            'Final rappel',
            'Final rappel',
            'Final rappel',
            'Final rappel',
          ]}
        />
        <Image image={data._16} caption="Final rappel" />
        <p>
          The <G>approach</G> and waterfall are short enough that we left the
          rigging and rappelled it multiple times.
        </p>
        <Carousel
          images={[data._17, data._18, data._19, data._20]}
          captions={[
            '',
            '',
            'Some twisted tree chunk in the creek bed',
            'Bottom of the final rappel',
          ]}
        />
        <p>
          The hike back was pleasant but hot. By the end of the it all I wanted
          to do was get back in the waterfall. Can't wait to go back here again.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2021-07-05-adams-canyon')(
  Blog_2021_07_05_Adams_Canyon
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/17.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/18.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/19.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: { eq: "images/blog/2021-07-05-adams-canyon/20.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
